import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["options", "template", "option"]

  connect() {
    this.choiceTypeChanged()
  }

  add(event) {
    event.preventDefault()
    const template = this.templateTarget.innerHTML
    const timestamp = new Date().getTime()
    const newOption = template.replace(/NEW_RECORD/g, timestamp)
    this.optionsTarget.insertAdjacentHTML('beforeend', newOption)
    this.choiceTypeChanged()
  }

  remove(event) {
    event.preventDefault()
    const optionField = event.target.closest('.nested-fields')
    if (optionField.dataset.newRecord === "true") {
      optionField.remove()
    } else {
      const destroyField = optionField.querySelector("input[name*='_destroy']")
      destroyField.value = 1
      optionField.style.display = 'none'
    }
  }

  choiceTypeChanged(event) {
    const choiceTypeSelect = document.querySelector('select[name="poll[choice_type]"]')
    const choiceType = choiceTypeSelect.value

    this.optionTargets.forEach(option => {
      const nameField = option.querySelector('[data-choice-type="name"]')
      const webField = option.querySelector('[data-choice-type="versus"]')

      if (choiceType === 'versus') {
        if (nameField) { nameField.style.display = 'none' }
        if (webField) { webField.style.display = '' }
      } else {
        if (nameField) { nameField.style.display = '' }
        if (webField) { webField.style.display = 'none' }
      }
    })
  }
}