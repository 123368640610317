import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="show-more"
export default class extends Controller {
  static targets = ["content", "button"]
  static values = {
    moreText: String,
    lessText: String,
    defaultLine: Number
  }

  connect() {
    // 检查内容是否超过默认的行数
    this.checkContentOverflow()
    this.toggleLineClamp(true) // 默认应用 line-clamp
  }

  checkContentOverflow() {
    const lineHeight = parseFloat(getComputedStyle(this.contentTarget).lineHeight)
    const maxHeight = this.defaultLineValue * lineHeight
    const actualHeight = this.contentTarget.scrollHeight

    // 如果内容没有超过默认行数，隐藏按钮
    if (actualHeight <= maxHeight) {
      this.buttonTarget.classList.add('hidden')
    } else {
      this.buttonTarget.classList.remove('hidden')
      this.updateButtonLabel(this.moreTextValue)
    }
  }

  toggle() {
    // Toggle line-clamp class
    if (this.contentTarget.classList.contains(`line-clamp-${this.defaultLineValue}`)) {
      this.toggleLineClamp(false)
      this.updateButtonLabel(this.lessTextValue)
    } else {
      this.toggleLineClamp(true)
      this.updateButtonLabel(this.moreTextValue)
    }
  }

  toggleLineClamp(add) {
    const className = `line-clamp-${this.defaultLineValue}`
    if (add) {
      this.contentTarget.classList.add(className)
    } else {
      this.contentTarget.classList.remove(className)
    }
  }

  updateButtonLabel(label) {
    this.buttonTarget.textContent = label
  }
}
