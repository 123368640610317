import { Application } from "@hotwired/stimulus";
Turbo.setProgressBarDelay(1000)
import LocalTime from "local-time";
LocalTime.start();

const application = Application.start();

import {
	Alert,
	Autosave,
	ColorPreview,
	Dropdown,
	Modal,
	Tabs,
	Popover,
	Toggle,
	Slideover,
} from "tailwindcss-stimulus-components";
application.register("alert", Alert);
application.register("autosave", Autosave);
application.register("color-preview", ColorPreview);
application.register("dropdown", Dropdown);
application.register("modal", Modal);
application.register("popover", Popover);
application.register("slideover", Slideover);
application.register("tabs", Tabs);
application.register("toggle", Toggle);

import {
	AutoSubmitFormController,
	BackLinkController,
	TeleportController,
	CheckboxXORController,
	SignalInputController,
	SignalVisibilityController,
	FallbackImageController,
	FormSaveController
} from "stimulus-library";
application.register("auto-submit-form", AutoSubmitFormController);
application.register("back-link", BackLinkController);
application.register("teleport", TeleportController);
application.register("checkbox-xor", CheckboxXORController);
application.register("signal-input", SignalInputController);
application.register("signal-visibility", SignalVisibilityController);
application.register("fallback-image", FallbackImageController);
application.register("form-save", FormSaveController);


import Clipboard from '@stimulus-components/clipboard'
application.register('clipboard', Clipboard)

import "rhino-editor";
import HwComboboxController from "@josefarias/hotwire_combobox";
application.register("hw-combobox", HwComboboxController);

// Configure Stimulus development experience
application.debug = true;
window.Stimulus = application;

export { application };
