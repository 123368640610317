import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="removal"
export default class extends Controller {
  static targets = ["removable"];
  connect() {
    console.log("Removal controller connected");
  }

  remove() {
    this.removableTarget.remove(); // 移除元素
  }
}
