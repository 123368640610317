import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="link-active"
export default class extends Controller {
	static values = {
		url: String,
		model: { type: Array, default: [] },
	};
	static classes = ["active"];

	connect() {
		this.updateActiveClass();
	}

	updateActiveClass() {
		const path = window.location.pathname;
		if (this.isRootPath(path) || this.isMatchingPath(path)) {
			this.element.classList.add(this.activeClass);
		}
	}

	isRootPath(path) {
		return path === "/" && this.urlValue === path;
	}

	isMatchingPath(path) {
		const pathParts = path.split("/").filter(Boolean);
		// Check if any part of the path matches any value in modelValue
		const matching = pathParts.some((part) => this.modelValue.includes(part));
		return matching || this.urlValue === path;
	}
}
