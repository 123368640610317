import { Controller } from "@hotwired/stimulus"
import markdownit from 'markdown-it'

// Connects to data-controller="markdown-display"
export default class extends Controller {
  connect() {
    const md = markdownit({
      html: true,
      linkify: true,
      typographer: true
    })
    this.element.innerHTML = md.render(this.element.textContent.trim())
  }
}
